import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import comicAudio from "../../assets/audio/comic.mp3";
import battleAudio from "../../assets/audio/battle.mp3";
import jutsuAudio from "../../assets/audio/jutsu.mp3";
import playIcon from "../../assets/icons/play.svg";

const NarutoIntro = ({onNextClick = () => {}}) => {

    let comicSound = useRef({ current: null });
    let battleSound = useRef({ current: null });
    let jutsuSound = useRef({ current: null });

    const [currentIndex, setCurrentIndex] = useState(1);
    const [show, setShow] = useState(true);
    const [pause, setPause] = useState(true);
    const [fade, setFade] = useState(false);
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    useEffect(() => {
        loadData();
    }, []);
    const loadData = async () => {
        setLoading(true);
        const images = await fetchImages();
        setImages(images);
        setLoading(false);
    };
    const fetchImages = () => {
        return new Promise((resolve, reject) => {
            let imagesArray = [];
            for(let i = 1; i < 52; i++) {
                const img = new Image();
                img.src = `../../mh2021comic/${i}.jpg`;
                imagesArray.push(img);
            }
            resolve(imagesArray);
        });
    };
    useEffect(() => {
        if(!pause) {
            const interval = setInterval(() => {
                setCurrentIndex(currentIndex + 1);
            }, 200);
            if(currentIndex > 51) {
                clearInterval(interval);
            }
            return () => clearInterval(interval);
        }
    }, [currentIndex, pause]);
    const handleOnNextClick = async () => {
        setShow(false);
        setFade(true);
        battleSound.current.pause();
        await jutsuSound.current.play();
        onNextClick();
    };
    const onPlayClick = () => {
        setPause(false);
        comicSound.current.play();
        battleSound.current.play();
    };
    return (
        <div style={{width: '100%'}}>
            <audio src={comicAudio} ref={comicSound} preload="auto" />
            <audio loop={true} src={battleAudio} ref={battleSound} preload="auto" />
            <audio src={jutsuAudio} ref={jutsuSound} preload="auto" />
            {
                loading ? <span>Cargando...</span> : (
                    pause ? <PlayButtonWrapper>
                            <PlayButton onClick={onPlayClick}>
                                <img src={playIcon} alt="play" />
                            </PlayButton>
                            <span>Sube el volumen antes de empezar!</span>
                        </PlayButtonWrapper>
                        :
                        <NarutoIntroWrapper
                            onAnimationEnd={() => {setFade(false);}}
                            style={{display: show ? 'block' : 'none'}}
                        >


                            <div id="marvel">6</div>
                            <div id="marvel-overlay" />
                            <div id="bg" />
                            <a onClick={handleOnNextClick} className="btn--next">Apachurrame!</a>
                            <div id="background-image" style={{backgroundImage: `url('../../mh2021comic/${currentIndex}.jpg')`}} />
                        </NarutoIntroWrapper>
                )
            }
        </div>
    );
};
const PlayButtonWrapper = styled.div`
  padding: 20px;
  span {
    display: block;
    flex: 1;
    font-size: 20px;
    font-weight: 600;
    color: #0c0c0c;
    text-align: center;
    width: 100%;
  }
`;
const PlayButton = styled.button`
  position: relative;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  border: none;
  box-shadow: 0 0 0 0 rgba(218, 147, 10, 0.7);
  border-radius: 50%;
  background-color: #f2a30b;
  background-size:cover;
  background-repeat: no-repeat;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
  }
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  &:hover {
    animation: none;
  }
  @keyframes pulse {to {box-shadow: 0 0 0 45px rgba(218, 147, 10, 0);}}
`;
const NarutoIntroWrapper = styled.div`
    width: 100%;
    //&.hide-animation {
    //  animation: all 2s ease-out forwards;
    //}
    #background-image {
        width: 100%;
        height: 100vh;
        background: transparent no-repeat center;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
    }
    #marvel-overlay {
      background-color: #cbeef3;
      position: fixed;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      animation: marvel-overlay 11s ease 2s forwards;
    }
    #marvel {
      text-align: center;
      color: transparent;
      font-size: 230vw;
      font-family: "IBMPlexSans", sans-serif;
      font-weight: 700;
      -webkit-text-stroke: 4px #657779;
      letter-spacing: -1vw;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      position:relative;
      top: 0; left: 0; right: 0; bottom: 0;
      z-index: 3;
      animation: marvel 10s ease 2s forwards;
      white-space: nowrap;
    }
    #marvel::after {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 20px;
      left: 20px;
      width: 0;
      height: 5px;
      background: #657779;
      animation-name: strike;
      animation-duration: 300ms;
      animation-delay: 13s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards; 
    }
    @keyframes strike{
      0%   { width : 0; }
      100% { width: 90vw; }
    }
    .btn--next {
      opacity: 0;
      bottom: 20px;
      left: 0;
      right: 0;
      position: fixed;
      z-index: 4;
      background-color: #fff;
      font-weight: 600;
      border-radius: 20px;
      padding: 20px 10px;
      margin-right: 20px;
      margin-left: 20px;
      text-align: center;
      color: #657779;
      font-size: 20px;
      //animation-name: next-btn;
      //animation-duration: 1s;
      //animation: next-btn 1s ease 1s forwards;
      animation-name: fadeIn, bounce;
      animation-delay: 13s, 2s;
      animation-duration: 2s, 500ms;
      animation-fill-mode: forwards, forwards;
      animation-timing-function: ease, ease;
      animation-iteration-count: 1, infinite;
      animation-direction: normal, alternate;
    }
    @keyframes fadeOut {
      0% {
        opacity: 1;
        //display: block;
        transform: translateY(0);
      }
      100% {
        //display: none;
        opacity: 0;
        transform: translateY(-100%);
      }
    }
    @keyframes bounce {
      0% {
        bottom: 20px;
      }
      100% {
        bottom: 35px;
      }
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes marvel-overlay {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes marvel {
      0% {
        opacity: 0;
        font-size: 200vw;
        color: rgba(255, 255, 255, 0);
        text-decoration: unset;
        -webkit-text-stroke-color: #657779;
      }
      20% {
        opacity: 1;
        color: rgba(255, 255, 255, 0);
        -webkit-text-stroke-color: #657779;
      }
      60% {
        color: rgba(255, 255, 255, 0.5);
        -webkit-text-stroke-color: #657779;
      }
      70% {
        color: rgba(255, 255, 255, 0.8);
        -webkit-text-stroke-color: #657779;
      }
      90% {
        color: rgba(255, 255, 255, 0.9);
        -webkit-text-stroke-color: #657779;
      }
      100% {
        opacity: 1;
        font-size: 50vw;
        color: rgba(255, 255, 255, 1);
        -webkit-text-stroke-color: transparent;
      }
    }
    
    #bg {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 1;
      background-image: url('../../mh2021comic/1.jpg');
      background-attachment: fixed;
      background-size: cover;
      background-color: transparent;
      background-position: center;
      z-index: 1;
      transform: scale(1.4);
      animation: hideBg 11s ease 0s forwards;
    }
    
    @keyframes hideBg {
      0% {
        opacity: 1;
        transform: scale(1.4);
      }
      60% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
        transform: scale(1);
      }
    }
`;

export default NarutoIntro;
