import React, {useState} from "react";
import styled from "styled-components";
import GlitchButton from "./GlitchButton";
import NarutoPixel from "./narutoPixel";

const colorArray = [
    '#ffe182',
    '#fff',
    '#cbeef3',
    '#d4adcf',
    '#f26a8d',
    '#f49cbb',
    '#cbeef3',
    '#d4adcf',
    '#98FB98',
    '#f49cbb',
    '#cbeef3',
    '#d4adcf',
    '#ffe182',
    '#98FB98',
    '#cbeef3',
    '#FF7F7F',
    '#98FB98',
    '#cbeef3',
    '#D3D3D3',
    '#342c2c',
];

const Letter = ({onEndClick = () => {}}) => {
    const [step, setStep] = useState(0);
    const onNextClick = () => {
        setStep(step + 1);
    };
    const handleOnEndClick = () => {
        onEndClick();
    };
    return (
        <Wrapper style={{backgroundColor: colorArray[step]}}>
            {step === 0 &&
                <Step>
                    <Text>Hola bg</Text>
                    <NextButton onClick={onNextClick}>Siguiente</NextButton>
                </Step>
            }
            {step === 1 &&
                <Step style={{alignContent: 'flex-start'}} >
                    <Text style={{marginTop: "20%", marginBottom: '100px'}}>Ya van <b>SEIS</b> añotes desde aquel día! 😬</Text>
                    <img alt="playa" src="../../mh2021comic/1.jpg" width={300} height={300} />
                    <NextButton onClick={onNextClick}>Siguiente</NextButton>
                </Step>
            }
            {step === 2 &&
                <Step>
                    <Text>Ahí te van unos datos curiosos... llevamos <b>2190</b> bg noches, cada quien.</Text>
                    <NextButton onClick={onNextClick}>Siguiente</NextButton>
                </Step>
            }
            {step === 3 &&
            <Step>
                <Text>Llevamos más de <b>55,000+</b> mensajes en telegram y <b>130,000+</b> en WhatsApp.</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 4 &&
            <Step>
                <Text>🤯</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 5 &&
            <Step>
                <Text>Pero lo más importante de todo...</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 6 &&
            <Step>
                <Text>Estamos más unidos que nunca.</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 7 &&
            <Step>
                <Text>Cualquier problema que tengas, cuando necesites apoyo o desahogarte...</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 8 &&
            <Step>
                <Text>Con quien reirte, llorar, jugar o bailar(hago el intento)...</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 9 &&
            <Step>
                <Text>O simplemente platicar.</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 10 &&
            <Step>
                <Text>Pase lo que pase siempre voy a estar ahí para ti.</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 11 &&
            <Step>
                <Text>Por otro lado, creo que ya te diste cuenta...</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 12 &&
            <Step style={{alignContent: 'flex-start'}}>
                <Text style={{marginTop: "20%", marginBottom: '0'}}>Me <b>fascina</b> que veamos juntos Naruto y anime... me mata 🥴</Text>
                <NarutoPixel />
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 13 &&
            <Step>
                <Text>Aparte creo que si no me pongo las pilas pronto me rebasas en Call of Duty (love it).</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 14 &&
            <Step>
                <Text>Sigamos escribiendo nuestro camino ninja!</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 15 &&
            <Step>
                <Text>Disfruta de tus regalitos 🤪️</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 16 &&
            <Step>
                <Text>Feliz aniversario!</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 17 &&
            <Step>
                <Text>Te amo bg ❤️</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 18 &&
            <Step>
                <Text>Escuhas algo?...️</Text>
                <NextButton onClick={onNextClick}>Siguiente</NextButton>
            </Step>
            }
            {step === 19 &&
            <Step>
                <Text style={{color: '#fff'}}>Que es eso?...️</Text>
                <GlitchButton onClick={handleOnEndClick} text="SIGUIENTE" />
            </Step>
            }
        </Wrapper>
    );
};

const NextButton = styled.a`
  opacity: 0;
      bottom: 20px;
      left: 0;
      right: 0;
      position: fixed;
      z-index: 4;
      background-color: #fff;
      font-weight: 600;
      border-radius: 20px;
      padding: 20px 10px;
      margin-right: 20px;
      margin-left: 20px;
      text-align: center;
      color: #657779;
      font-size: 20px;
      //animation-name: next-btn;
      //animation-duration: 1s;
      //animation: next-btn 1s ease 1s forwards;
      animation-name: fadeIn, bounce;
      animation-delay: 2s, 2s;
      animation-duration: 2s, 500ms;
      animation-fill-mode: forwards, forwards;
      animation-timing-function: ease, ease;
      animation-iteration-count: 1, infinite;
      animation-direction: normal, alternate;
      
      @keyframes bounce {
      0% {
        bottom: 20px;
      }
      100% {
        bottom: 35px;
      }
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
`;

const Step = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  img {
    animation: fadeIn 0.3s ease 2s forwards;
    animation-delay: 0s;
  }
  @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
`;

const Text = styled.div`
  font-size: 30px;
  color: #0c0c0c;
  font-weight: 500;
  opacity: 0;
  padding-right: 20px;
  padding-left: 20px;
  animation: fadeIn 0.3s ease 2s forwards;
  animation-delay: 0s;
  @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cbeef3;
`;

export default Letter;
