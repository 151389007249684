import React, {useEffect, useState} from "react";
import styled from "styled-components";

const GlitchButton = ({text = 'button', onClick}) => {
    const [currentGlitchLetterIndex, setCurrentGlitchLetterIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            const randomNumber = Math.floor(Math.random() * text.length) + 1;
            setCurrentGlitchLetterIndex(randomNumber);
        }, 250);
        return () => clearInterval(interval);
    }, []);
    return (
        <Wrapper onClick={onClick}>
            {text.toString().split('').map((letter, index) => <span className={currentGlitchLetterIndex === index ? 'rotate' : ''} id={`glitch-letter-${letter}`}>{letter}</span>)}
        </Wrapper>
    );
};

const Wrapper = styled.div`
  bottom: 20px;
      left: 0;
      right: 0;
      position: fixed;
      z-index: 4;
      background-color: #342c2c;
      font-family: 'Helvetica Neue', sans-serif;
      letter-spacing: 2px;
      font-weight: 600;
      border-radius: 20px;
      padding: 20px 10px;
      margin-right: 20px;
      margin-left: 20px;
      text-align: center;
      color: #fff;
      font-size: 20px;
      .rotate {
      display: inline-block;
        transform: rotate(180deg);
        color: #aa130f;
      }
      &:hover {
        cursor: pointer;
      }
`;

export default GlitchButton;
