import React, {useState, useRef} from "react";
import styled from "styled-components";
import NarutoIntro from "../components/gift/NarutoIntro";
import painAudio from "../assets/audio/pain.mp3"
import daylightAudio from "../assets/audio/daylight.mp3"
import sharinganAudio from "../assets/audio/sharingan.mp3";
import Letter from "../components/gift/Letter";
import OneMoreThing from "../components/gift/OneMoreThing";

const Term = (props) => {

    let painSound = useRef({ current: null });
    let daylightSound = useRef({ current: null });
    let sharinganSound = useRef({ current: null });

    const [showLetter, setShowLetter] = useState(false);
    const [showOneMoreThing, setShowOneMoreThing] = useState(false);
    const [showNarutoIntro, setShowNarutoIntro] = useState(true);
    const onIntroNextClick  =() => {
        daylightSound.current.play();
        setShowLetter(true);
        setShowNarutoIntro(false);
    };
    const onLetterEndClick = async () => {
        setShowLetter(false);
        setShowOneMoreThing(true);
        daylightSound.current.pause();
        await sharinganSound.current.play();
        sharinganSound.current.onended = () => {
            painSound.current.play();
        };
    };
    return (
        <Wrapper>

            <audio loop={true} src={painAudio} ref={painSound} preload="auto" />
            <audio loop={true} src={daylightAudio} ref={daylightSound} preload="auto" />
            <audio src={sharinganAudio} ref={sharinganSound} preload="auto" />

            {showNarutoIntro && <NarutoIntro onNextClick={onIntroNextClick} /> }
            {showLetter && <Letter onEndClick={onLetterEndClick} />}
            {showOneMoreThing && <OneMoreThing /> }
        </Wrapper>
    )
};

const Wrapper = styled.div`
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Term;
