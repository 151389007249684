import React, {useState} from "react";
import styled from "styled-components";
import Sharingan from "./Sharingan";
import GlitchButton from "./GlitchButton";
import shorts1Img from "../../images/shortsakatsuki.jpg";

const OneMoreThing = () => {
    const [step, setStep] = useState(0);
    const onNextClick = () => {
        setStep(step + 1);
    };
    return (
        <Wrapper>
            {step === 0 &&
            <Step>
                <Sharingan style={{marginTop: '0'}} />
                <Text style={{color: '#fff'}}>Hay algo más...</Text>
                <GlitchButton onClick={onNextClick} text="SIGUIENTE" />
            </Step>
            }
            {step === 1 &&
            <Step>
                <Text style={{color: '#fff'}}>Otro regalo...</Text>
                <GlitchButton onClick={onNextClick} text="SIGUIENTE" />
            </Step>
            }
            {step === 2 &&
            <Step>
                <Text style={{color: '#fff'}}>Pero no llegó a tiempo... hm!️</Text>
                <GlitchButton onClick={onNextClick} text="SIGUIENTE" />
            </Step>
            }
            {step === 3 &&
            <Step style={{alignContent: 'flex-start'}}>
                <img src={shorts1Img} alt="shorts" />
                <Text style={{marginTop: "20%", marginBottom: '0', color: '#fff'}}>Te muestro un adelanto.</Text>
                <GlitchButton onClick={onNextClick} text="SIGUIENTE" />
            </Step>
            }
            {step === 4 &&
            <Step>
                <Text style={{color: '#fff'}}>Espero te gusten! Mientras tanto disfruta de mi genjutsu!</Text>
                <GlitchButton onClick={onNextClick} text="SIGUIENTE" />
            </Step>
            }
            {step === 5 &&
            <Step>
                <Text style={{color: '#fff'}}>"A place where someone still thinks about you is a place you can call home." - Jiraya</Text>
            </Step>
            }
        </Wrapper>
    );
};

const Wrapper = styled.div`
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
      z-index: 4;
      background-color: #342c2c;
      font-weight: 600;
      padding: 20px 10px;
      text-align: center;
      color: #657779;
      font-size: 20px;
`;

const Step = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  img {
    animation: fadeIn 0.3s ease 2s forwards;
    animation-delay: 0s;
  }
  @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
`;

const Text = styled.div`
  font-size: 30px;
  color: #0c0c0c;
  font-weight: 500;
  opacity: 0;
  padding-right: 20px;
  padding-left: 20px;
  animation: fadeIn 0.3s ease 2s forwards;
  animation-delay: 0s;
  @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
`;

export default OneMoreThing;
